// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-01-honey-launch-index-md": () => import("./../src/pages/blog/01-honey-launch/index.md" /* webpackChunkName: "component---src-pages-blog-01-honey-launch-index-md" */),
  "component---src-pages-blog-02-honey-land-index-md": () => import("./../src/pages/blog/02-honey-land/index.md" /* webpackChunkName: "component---src-pages-blog-02-honey-land-index-md" */),
  "component---src-pages-blog-03-honey-flow-index-md": () => import("./../src/pages/blog/03-honey-flow/index.md" /* webpackChunkName: "component---src-pages-blog-03-honey-flow-index-md" */),
  "component---src-pages-blog-04-a-fresh-start-index-md": () => import("./../src/pages/blog/04-a-fresh-start/index.md" /* webpackChunkName: "component---src-pages-blog-04-a-fresh-start-index-md" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bug-bounty-mdx": () => import("./../src/pages/bug-bounty.mdx" /* webpackChunkName: "component---src-pages-bug-bounty-mdx" */),
  "component---src-pages-confirm-js": () => import("./../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-docs-dao-01-participation-01-community-covenant-md": () => import("./../src/pages/docs/dao/01-Participation/01-community-covenant.md" /* webpackChunkName: "component---src-pages-docs-dao-01-participation-01-community-covenant-md" */),
  "component---src-pages-docs-dao-01-participation-02-acquiring-honey-md": () => import("./../src/pages/docs/dao/01-Participation/02-acquiring-honey.md" /* webpackChunkName: "component---src-pages-docs-dao-01-participation-02-acquiring-honey-md" */),
  "component---src-pages-docs-dao-01-participation-03-proposals-md": () => import("./../src/pages/docs/dao/01-Participation/03-proposals.md" /* webpackChunkName: "component---src-pages-docs-dao-01-participation-03-proposals-md" */),
  "component---src-pages-docs-dao-01-participation-04-swarms-md": () => import("./../src/pages/docs/dao/01-Participation/04-swarms.md" /* webpackChunkName: "component---src-pages-docs-dao-01-participation-04-swarms-md" */),
  "component---src-pages-docs-dao-01-participation-05-pollen-md": () => import("./../src/pages/docs/dao/01-Participation/05-pollen.md" /* webpackChunkName: "component---src-pages-docs-dao-01-participation-05-pollen-md" */),
  "component---src-pages-docs-dao-01-participation-06-keeper-md": () => import("./../src/pages/docs/dao/01-Participation/06-keeper.md" /* webpackChunkName: "component---src-pages-docs-dao-01-participation-06-keeper-md" */),
  "component---src-pages-docs-dao-01-participation-index-js": () => import("./../src/pages/docs/dao/01-Participation/index.js" /* webpackChunkName: "component---src-pages-docs-dao-01-participation-index-js" */),
  "component---src-pages-docs-dao-02-honeyswap-01-intro-md": () => import("./../src/pages/docs/dao/02-Honeyswap/01-intro.md" /* webpackChunkName: "component---src-pages-docs-dao-02-honeyswap-01-intro-md" */),
  "component---src-pages-docs-dao-02-honeyswap-index-js": () => import("./../src/pages/docs/dao/02-Honeyswap/index.js" /* webpackChunkName: "component---src-pages-docs-dao-02-honeyswap-index-js" */),
  "component---src-pages-docs-dao-index-md": () => import("./../src/pages/docs/dao/index.md" /* webpackChunkName: "component---src-pages-docs-dao-index-md" */),
  "component---src-pages-docs-honeyswap-01-protocol-overview-01-how-uniswap-works-md": () => import("./../src/pages/docs/honeyswap/01-protocol-overview/01-how-uniswap-works.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-01-protocol-overview-01-how-uniswap-works-md" */),
  "component---src-pages-docs-honeyswap-01-protocol-overview-02-ecosystem-participants-md": () => import("./../src/pages/docs/honeyswap/01-protocol-overview/02-ecosystem-participants.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-01-protocol-overview-02-ecosystem-participants-md" */),
  "component---src-pages-docs-honeyswap-01-protocol-overview-03-smart-contracts-md": () => import("./../src/pages/docs/honeyswap/01-protocol-overview/03-smart-contracts.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-01-protocol-overview-03-smart-contracts-md" */),
  "component---src-pages-docs-honeyswap-01-protocol-overview-04-glossary-md": () => import("./../src/pages/docs/honeyswap/01-protocol-overview/04-glossary.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-01-protocol-overview-04-glossary-md" */),
  "component---src-pages-docs-honeyswap-01-protocol-overview-index-js": () => import("./../src/pages/docs/honeyswap/01-protocol-overview/index.js" /* webpackChunkName: "component---src-pages-docs-honeyswap-01-protocol-overview-index-js" */),
  "component---src-pages-docs-honeyswap-01-protocol-overview-reference-advanced-topics-js": () => import("./../src/pages/docs/honeyswap/01-protocol-overview/reference/Advanced-topics.js" /* webpackChunkName: "component---src-pages-docs-honeyswap-01-protocol-overview-reference-advanced-topics-js" */),
  "component---src-pages-docs-honeyswap-01-protocol-overview-reference-core-concepts-js": () => import("./../src/pages/docs/honeyswap/01-protocol-overview/reference/Core-concepts.js" /* webpackChunkName: "component---src-pages-docs-honeyswap-01-protocol-overview-reference-core-concepts-js" */),
  "component---src-pages-docs-honeyswap-01-protocol-overview-reference-whitepaper-js": () => import("./../src/pages/docs/honeyswap/01-protocol-overview/reference/Whitepaper.js" /* webpackChunkName: "component---src-pages-docs-honeyswap-01-protocol-overview-reference-whitepaper-js" */),
  "component---src-pages-docs-honeyswap-02-core-concepts-01-swaps-md": () => import("./../src/pages/docs/honeyswap/02-core-concepts/01-swaps.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-02-core-concepts-01-swaps-md" */),
  "component---src-pages-docs-honeyswap-02-core-concepts-02-pools-md": () => import("./../src/pages/docs/honeyswap/02-core-concepts/02-pools.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-02-core-concepts-02-pools-md" */),
  "component---src-pages-docs-honeyswap-02-core-concepts-03-flash-swaps-md": () => import("./../src/pages/docs/honeyswap/02-core-concepts/03-flash-swaps.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-02-core-concepts-03-flash-swaps-md" */),
  "component---src-pages-docs-honeyswap-02-core-concepts-04-oracles-md": () => import("./../src/pages/docs/honeyswap/02-core-concepts/04-oracles.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-02-core-concepts-04-oracles-md" */),
  "component---src-pages-docs-honeyswap-02-core-concepts-index-js": () => import("./../src/pages/docs/honeyswap/02-core-concepts/index.js" /* webpackChunkName: "component---src-pages-docs-honeyswap-02-core-concepts-index-js" */),
  "component---src-pages-docs-honeyswap-02-core-concepts-reference-integration-quick-start-js": () => import("./../src/pages/docs/honeyswap/02-core-concepts/reference/Integration-quick-start.js" /* webpackChunkName: "component---src-pages-docs-honeyswap-02-core-concepts-reference-integration-quick-start-js" */),
  "component---src-pages-docs-honeyswap-03-advanced-topics-01-fees-md": () => import("./../src/pages/docs/honeyswap/03-advanced-topics/01-fees.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-03-advanced-topics-01-fees-md" */),
  "component---src-pages-docs-honeyswap-03-advanced-topics-02-pricing-md": () => import("./../src/pages/docs/honeyswap/03-advanced-topics/02-pricing.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-03-advanced-topics-02-pricing-md" */),
  "component---src-pages-docs-honeyswap-03-advanced-topics-03-understanding-returns-md": () => import("./../src/pages/docs/honeyswap/03-advanced-topics/03-understanding-returns.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-03-advanced-topics-03-understanding-returns-md" */),
  "component---src-pages-docs-honeyswap-03-advanced-topics-04-security-md": () => import("./../src/pages/docs/honeyswap/03-advanced-topics/04-security.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-03-advanced-topics-04-security-md" */),
  "component---src-pages-docs-honeyswap-03-advanced-topics-05-math-md": () => import("./../src/pages/docs/honeyswap/03-advanced-topics/05-math.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-03-advanced-topics-05-math-md" */),
  "component---src-pages-docs-honeyswap-03-advanced-topics-06-research-md": () => import("./../src/pages/docs/honeyswap/03-advanced-topics/06-research.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-03-advanced-topics-06-research-md" */),
  "component---src-pages-docs-honeyswap-03-advanced-topics-index-js": () => import("./../src/pages/docs/honeyswap/03-advanced-topics/index.js" /* webpackChunkName: "component---src-pages-docs-honeyswap-03-advanced-topics-index-js" */),
  "component---src-pages-docs-honeyswap-06-smart-contract-integration-01-quick-start-md": () => import("./../src/pages/docs/honeyswap/06-smart-contract-integration/01-quick-start.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-06-smart-contract-integration-01-quick-start-md" */),
  "component---src-pages-docs-honeyswap-06-smart-contract-integration-02-trading-from-a-smart-contract-md": () => import("./../src/pages/docs/honeyswap/06-smart-contract-integration/02-trading-from-a-smart-contract.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-06-smart-contract-integration-02-trading-from-a-smart-contract-md" */),
  "component---src-pages-docs-honeyswap-06-smart-contract-integration-03-providing-liquidity-md": () => import("./../src/pages/docs/honeyswap/06-smart-contract-integration/03-providing-liquidity.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-06-smart-contract-integration-03-providing-liquidity-md" */),
  "component---src-pages-docs-honeyswap-06-smart-contract-integration-04-building-an-oracle-md": () => import("./../src/pages/docs/honeyswap/06-smart-contract-integration/04-building-an-oracle.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-06-smart-contract-integration-04-building-an-oracle-md" */),
  "component---src-pages-docs-honeyswap-06-smart-contract-integration-05-using-flash-swaps-md": () => import("./../src/pages/docs/honeyswap/06-smart-contract-integration/05-using-flash-swaps.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-06-smart-contract-integration-05-using-flash-swaps-md" */),
  "component---src-pages-docs-honeyswap-06-smart-contract-integration-06-getting-pair-addresses-md": () => import("./../src/pages/docs/honeyswap/06-smart-contract-integration/06-getting-pair-addresses.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-06-smart-contract-integration-06-getting-pair-addresses-md" */),
  "component---src-pages-docs-honeyswap-06-smart-contract-integration-06-supporting-meta-transactions-md": () => import("./../src/pages/docs/honeyswap/06-smart-contract-integration/06-supporting-meta-transactions.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-06-smart-contract-integration-06-supporting-meta-transactions-md" */),
  "component---src-pages-docs-honeyswap-06-smart-contract-integration-index-js": () => import("./../src/pages/docs/honeyswap/06-smart-contract-integration/index.js" /* webpackChunkName: "component---src-pages-docs-honeyswap-06-smart-contract-integration-index-js" */),
  "component---src-pages-docs-honeyswap-06-smart-contract-integration-reference-smart-contract-architecture-js": () => import("./../src/pages/docs/honeyswap/06-smart-contract-integration/reference/smart-contract-architecture.js" /* webpackChunkName: "component---src-pages-docs-honeyswap-06-smart-contract-integration-reference-smart-contract-architecture-js" */),
  "component---src-pages-docs-honeyswap-07-interface-integration-01-using-the-api-md": () => import("./../src/pages/docs/honeyswap/07-interface-integration/01-using-the-api.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-07-interface-integration-01-using-the-api-md" */),
  "component---src-pages-docs-honeyswap-07-interface-integration-02-custom-interface-linking-md": () => import("./../src/pages/docs/honeyswap/07-interface-integration/02-custom-interface-linking.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-07-interface-integration-02-custom-interface-linking-md" */),
  "component---src-pages-docs-honeyswap-07-interface-integration-03-iframe-integration-md": () => import("./../src/pages/docs/honeyswap/07-interface-integration/03-iframe-integration.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-07-interface-integration-03-iframe-integration-md" */),
  "component---src-pages-docs-honeyswap-07-interface-integration-index-js": () => import("./../src/pages/docs/honeyswap/07-interface-integration/index.js" /* webpackChunkName: "component---src-pages-docs-honeyswap-07-interface-integration-index-js" */),
  "component---src-pages-docs-honeyswap-09-smart-contracts-01-factory-md": () => import("./../src/pages/docs/honeyswap/09-smart-contracts/01-factory.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-09-smart-contracts-01-factory-md" */),
  "component---src-pages-docs-honeyswap-09-smart-contracts-02-pair-md": () => import("./../src/pages/docs/honeyswap/09-smart-contracts/02-pair.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-09-smart-contracts-02-pair-md" */),
  "component---src-pages-docs-honeyswap-09-smart-contracts-03-pair-erc-20-md": () => import("./../src/pages/docs/honeyswap/09-smart-contracts/03-pair-erc-20.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-09-smart-contracts-03-pair-erc-20-md" */),
  "component---src-pages-docs-honeyswap-09-smart-contracts-04-library-md": () => import("./../src/pages/docs/honeyswap/09-smart-contracts/04-library.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-09-smart-contracts-04-library-md" */),
  "component---src-pages-docs-honeyswap-09-smart-contracts-06-router-02-md": () => import("./../src/pages/docs/honeyswap/09-smart-contracts/06-router02.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-09-smart-contracts-06-router-02-md" */),
  "component---src-pages-docs-honeyswap-09-smart-contracts-index-js": () => import("./../src/pages/docs/honeyswap/09-smart-contracts/index.js" /* webpackChunkName: "component---src-pages-docs-honeyswap-09-smart-contracts-index-js" */),
  "component---src-pages-docs-honeyswap-10-api-01-overview-md": () => import("./../src/pages/docs/honeyswap/10-API/01-overview.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-10-api-01-overview-md" */),
  "component---src-pages-docs-honeyswap-10-api-02-entities-md": () => import("./../src/pages/docs/honeyswap/10-API/02-entities.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-10-api-02-entities-md" */),
  "component---src-pages-docs-honeyswap-10-api-03-queries-md": () => import("./../src/pages/docs/honeyswap/10-API/03-queries.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-10-api-03-queries-md" */),
  "component---src-pages-docs-honeyswap-10-api-index-js": () => import("./../src/pages/docs/honeyswap/10-API/index.js" /* webpackChunkName: "component---src-pages-docs-honeyswap-10-api-index-js" */),
  "component---src-pages-docs-honeyswap-index-md": () => import("./../src/pages/docs/honeyswap/index.md" /* webpackChunkName: "component---src-pages-docs-honeyswap-index-md" */),
  "component---src-pages-docs-index-js": () => import("./../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-faq-index-md": () => import("./../src/pages/faq/index.md" /* webpackChunkName: "component---src-pages-faq-index-md" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

